import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import style from "./Style.module.css";
import Util from "../../Util";

const banks = [
  "ธ. ไทยพาณิชย์",
  "ธ. กรุงเทพ จำกัด (มหาชน)",
  "ธ. กสิกรไทย จำกัด (มหาชน)",
  "ธ. กรุงไทย จำกัด (มหาชน)",
  "ธ. ทหารไทย จำกัด (มหาชน)",
  "ธ. ออมสิน",
  "ธ. กรุงศรีอยุธยา จำกัด (มหาชน)",
  "ธ. อาคารสงเคราะห์",
  "ธ. เพื่อการเกษตรและสหกรณ์การเกษตร",
  "ธ. ซิตี้แบงก์ เอ็น.เอ.",
  "ธ. อิสลามแห่งประเทศไทย",
  "ธ. ซูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น",
  "ธ. สแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)",
  "ธ. ซีไอเอ็มบี ไทย จำกัด (มหาชน)",
  "ธ. ยูโอบี จำกัด (มหาชน)",
  "ธ. มิซูโฮ จำกัด สาขากรุงเทพฯ",
  "ธ. ธนชาต จำกัด (มหาชน)",
  "ธ. ทิสโก้ จำกัด (มหาชน)",
  "ธ. เกียรตินาคิน จำกัด (มหาชน)",
  "ธ. ไอซีบีซี (ไทย) จำกัด (มหาชน)",
  "ธ. ไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)",
  "ธ. แลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)",
];

class Nav extends React.Component {
  constructor(props) {
    super(props);
    var check = [
      "realName",
      "codeName",
      "peopleID",
      "bankInfo",
      "addSmall",
      "addSmallDistrict",
      "addDistrict",
      "addProvince",
      "addPostal",
      "phone",
    ];
    for (var i = 0; i < check.length; i++)
      if (!props.info[check[i]]) props.info[check[i]] = "";
    this.state = props.info;
  }

  render() {
    return (
      <Container>
        <div className={style.dashPanel}>
          <div className={style.header}>แก้ไขข้อมูลผู้ขายชีท</div>
          <div className={style.label}>นามปากกา(ภาษาไทยหรืออังกฤษเท่านั้น)</div>
          <input
            className={style.textForm}
            type="text"
            value={this.state.codeName}
            onChange={(event) => {
              this.setState({ codeName: event.currentTarget.value });
            }}
          />
          <div className={style.label}>ชื่อจริง (ระบุคำนำหน้าด้วย)</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.realName}
            onChange={(event) => {
              this.setState({ realName: event.currentTarget.value });
            }}
          />
          <div className={style.label}>เบอร์โทรติดต่อ</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.phone}
            onChange={(event) => {
              this.setState({ phone: event.currentTarget.value.trim() });
            }}
          />
          <div className={style.label}>เลขบัตรประชาชน 13 หลัก</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.peopleID}
            onChange={(event) => {
              this.setState({ peopleID: event.currentTarget.value.trim() });
            }}
          />
          <div className={style.label}>ธนาคารที่ใช้รับรายได้</div>
          <select
            className={style.textForm}
            value={this.state.bankName}
            onChange={(event) => {
              this.setState({ bankName: event.currentTarget.value });
            }}
          >
            {this.genBankOptions()}
          </select>
          <div className={style.label}>เลขบัญชีธนาคาร(ตัวเลขเท่านั้น)</div>
          <input
            className={style.textForm}
            type="number"
            placeholder="Ex. 1234567890123"
            value={this.state.bankNumber}
            onChange={(event) => {
              this.setState({ bankNumber: event.currentTarget.value.trim() });
            }}
          />
          <div className={style.label}>ที่อยู่ (เพื่อออกใบกำกับภาษี)</div>
          <hr />
          <div className={style.label}>บ้านเลขที่ ถนน ตรอก/ซอย หมู่</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.addSmall}
            onChange={(event) => {
              this.setState({ addSmall: event.currentTarget.value });
            }}
          />
          <div className={style.label}>ตำบล/แขวง</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.addSmallDistrict}
            onChange={(event) => {
              this.setState({ addSmallDistrict: event.currentTarget.value });
            }}
          />
          <div className={style.label}>อำเภอ/เขต</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.addDistrict}
            onChange={(event) => {
              this.setState({ addDistrict: event.currentTarget.value });
            }}
          />
          <div className={style.label}>จังหวัด</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.addProvince}
            onChange={(event) => {
              this.setState({ addProvince: event.currentTarget.value });
            }}
          />
          <div className={style.label}>เลขไปรษณีย์</div>
          <input
            className={style.textForm}
            type="text"
            placeholder=""
            value={this.state.addPostal}
            onChange={(event) => {
              this.setState({ addPostal: event.currentTarget.value });
            }}
          />
          <div
            onClick={() => {
              this.saveData();
            }}
            className={style.registerButton}
          >
            แก้ไข
          </div>
        </div>
      </Container>
    );
  }

  saveData = () => {
    let phone = this.state.phone;
    let peopleID = this.state.peopleID;
    let bankNumber = this.state.bankNumber;
    let error = "";

    // Optional check for phone
    if (phone.length > 0 && !/^(?:\d{10}|\+66\d{9})$/.test(phone)) {
      error +=
        "หมายเลขโทรศัพท์ไม่ถูกต้อง (รับเฉพาะตัวเลข 10 หลัก หรือ +66..........)\n";
    }

    // Optional check for peopleId
    if (peopleID.length > 0 && !/^\d{13}$/.test(peopleID)) {
      error += "เลขบัตรประชาชน 13 หลักไม่ถูกต้อง (รับเฉพาะตัวเลข 13 หลัก)\n";
    }

    // Optional check for bankNumber
    if (!/^(?:\d{10}|\d{11}|\d{12}|\d{13}|\d{14}|\d{15})$/.test(bankNumber)) {
      error += "เลขบัญชีธนาคารไม่ถูกต้อง (รับเฉพาะตัวเลข 10-15 หลัก)\n";
    }

    if (error.length > 0) {
      alert(error);
    } else {
      this.props.save(this.state);
    }
  };

  genBankOptions = () => {
    return banks.map((b) => {
      return (
        <option label={b} key={b} value={b}>
          {b}
        </option>
      );
    });
  };
}

export default Nav;
