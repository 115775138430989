import React from "react";
import { Link, Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";
import qmark from "../../asset/qmark.png";
import confirm from "../../asset/alertsell.jpg";
import bannersell from "../../asset/bannersell.jpg";
import exclass from "../../asset/classexplain.png";
import exdetail from "../../asset/detailexplain.png";
import "./style.css";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      data: {
        user: { infoEnter: true },
        sheet: {
          store: 0,
          name: "",
          classInfo: "",
          detail: "",
          price: "",
          examNum: 0,
          id: "",
          semester: 1,
          year: 2019,
          category: 0,
          message: "",
          triamsai: "",
        },
        store: [],
      },
      sCode: "",
      sName: "",
      cover: false,
      example: false,
      pdf: false,
      updCover: false,
      updSample: false,
      updPdf: false,
      saving: false,
      intervalId: null,
      informAuto: "เซฟล่าสุด: ไม่มี",
      showexclass: false,
      showexdetail: false,
    };
  }

  async componentDidMount() {
    var id = window.location.search.substring(1);
    var data = await Util.sendPost("/api/seller/getDraft", { id });
    if (!data.success) return this.props.history.push("/seller/mysheet");
    var name = data.data.sheet.name;
    await this.setState({
      sCode: name.substr(0, name.indexOf(" ")),
      sName: name.substr(name.indexOf(" ") + 1),
    });
    await this.setState({ data: data.data });
    await this.setState({ data: data.data });
    await this.reloadCover(id);
    await this.loadSamples();
    await this.loadPdf();
    var intervalId = setInterval(this.saveDraft, 10000);
    await this.setState({ intervalId: intervalId });
  }

  render() {
    const lang = Util.getLang();
    return (
      <React.Fragment>
        <div
          className={style.sheetPage}
          onClick={(event) => {
            if (event.target.id != "forbid1" && event.target.id != "forbid2")
              this.setState({ showexdetail: false, showexclass: false });
          }}
        >
          <Nav current="seller" user={this.state.data.user} />
          <Container>
            <div className={style.container}>
              <div className={style.blankTop} />
              <div className={style.header}>ชีทใหม่ / แก้ไขชีท</div>
              <div className={style.redexplain}>
                *กรุณากรอกข้อมูลให้ครบทุกช่อง*
              </div>
              <div className={style.explain}>
                เซฟอัตโนมัติทุก 10 วินาที หากเผลอปิดสามารถไปหาชีทนี้ในหน้า
                'ชีทของฉัน' เพื่อแก้ไขต่อได้
              </div>
              <div className={style.explainLabel}>{this.state.informAuto}</div>
              <hr />

              {this.state.data.sheet.status === 3 && (
                <div className={style.reject}>
                  <div className={style.rejectHeader}>
                    ชีทถูกปฏิเสธ เนื่องจาก :
                  </div>
                  <div className={style.rejectReason}>
                    {this.nl2br(this.state.data.sheet.reason)}
                  </div>
                </div>
              )}

              <img className={style.banner} src={bannersell} alt="banner" />

              <div className={style.label}>
                ชื่อสถาบันที่ต้องการเผยแพร่ชีทนี้
              </div>
              <div className={style.explainLabel}>
                สามารถพิมพ์ชื่อสถาบันเพื่อค้นหาได้
              </div>
              <SelectSearch
                className="select-search-box-editSheet"
                options={this.genUniOptions()}
                value={this.state.data.sheet.store.toString()}
                onChange={(value) => this.setUni(value)}
              />

              {![999999, 9997, 32].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>ชนิดของชีทสรุป</div>
                  <div className={style.explainLabel}>
                    มิดเทอม, ไฟนอล, อ่านเสริมเข้ามหาวิทยาลัย, อ่านเข้า ม.4
                  </div>
                  <select
                    className={style["textField"]}
                    value={this.state.data.sheet.category}
                    onChange={(event) => {
                      this.setField("category", event.currentTarget.value);
                    }}
                  >
                    {this.genCatOptions()}
                  </select>
                </React.Fragment>
              )}

              {![999999, 9997, 32].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>ภาค / ปีการศึกษา</div>
                  <div className={style.explainLabelRed}>
                    ปีการศึกษาอ้างอิงจากของมหาวิทยาลัย เช่นการสอบรอบมีนาคม 2021{" "}
                    <b>ให้เลือกเป็นปีการศึกษา 2020</b>
                  </div>
                  <div className={style.explainLabel}>
                    3 หมายรวมถึงภาคฤดูร้อน
                  </div>
                  <select
                    className={style["textFieldHalf"]}
                    value={this.state.data.sheet.semester}
                    onChange={(event) => {
                      this.setField("semester", event.currentTarget.value);
                    }}
                  >
                    {this.genTermOptions()}
                  </select>
                  <select
                    className={style["textFieldHalf"]}
                    value={this.state.data.sheet.year}
                    onChange={(event) => {
                      this.setField("year", event.currentTarget.value);
                    }}
                  >
                    {this.genYearOptions()}
                  </select>
                </React.Fragment>
              )}

              {[999999, 9997].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>ชื่อบรรทัดแรกในภาพหน้าปก</div>
                  <div className={style.explainLabel}>
                    ตัวพิมพ์ใหญ่เท่านั้น และห้ามมีเว้นวรรค เช่น BIOLOGY
                  </div>
                  <input
                    type="text"
                    className={style["textField"]}
                    value={this.state.sCode}
                    onChange={(event) => {
                      this.setFieldCode(event.currentTarget.value);
                    }}
                  />
                </React.Fragment>
              )}
              {[32].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>ชื่อ</div>
                  <div className={style.explainLabel}>
                    ตัวพิมพ์ใหญ่เท่านั้น และห้ามมีเว้นวรรค เช่น BIOLOGY
                  </div>
                  <input
                    type="text"
                    className={style["textField"]}
                    value={this.state.sCode}
                    onChange={(event) => {
                      this.setFieldCode(event.currentTarget.value);
                    }}
                  />
                </React.Fragment>
              )}
              {![999999, 9997, 32].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>รหัสวิชา</div>
                  <div className={style.explainLabel}>
                    ตัวพิมพ์ใหญ่เท่านั้น และห้ามมีเว้นวรรค เช่น 2110611 หรือ
                    TU101
                  </div>
                  <input
                    type="text"
                    className={style["textField"]}
                    value={this.state.sCode}
                    onChange={(event) => {
                      this.setFieldCode(event.currentTarget.value);
                    }}
                  />
                </React.Fragment>
              )}

              {[999999, 9997].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>
                    ชื่อชีทบรรทัดที่สองในภาพหน้าปก
                  </div>
                  <div className={style.explainLabel}>เช่น ชีววิทยาทั่วไป</div>
                  <input
                    type="text"
                    className={style["textField"]}
                    value={this.state.sName}
                    onChange={(event) => {
                      this.setFieldName(event.currentTarget.value);
                    }}
                  />
                </React.Fragment>
              )}
              {![999999, 9997, 32].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>
                    ชื่อวิชา รวมข้อมูลที่อยากให้ปรากฎชัดๆบนหน้าปก
                  </div>
                  <div className={style.explainLabel}>
                    เช่น การเขียนโปรแกรมเบื้องต้น เซคพุธบ่าย
                  </div>
                  <input
                    type="text"
                    className={style["textField"]}
                    value={this.state.sName}
                    onChange={(event) => {
                      this.setFieldName(event.currentTarget.value);
                    }}
                  />
                </React.Fragment>
              )}

              {![999999, 9997, 32].includes(this.state.data.sheet.store) && (
                <React.Fragment>
                  <div className={style.label}>รายละเอียดคลาส / ตอนเรียน</div>
                  <div className={style.explainLabelExtra}>
                    <div className={style.qcontain}>
                      <div
                        className={style.qimage}
                        id="forbid1"
                        onClick={() =>
                          this.setState({
                            showexclass: !this.state.showexclass,
                          })
                        }
                      >
                        กดตรงนี้เพื่อดูตัวอย่าง
                      </div>
                      {this.state.showexclass && (
                        <img src={exclass} className={style.qclasspic} />
                      )}
                    </div>
                  </div>
                  <textarea
                    className={style["textArea"]}
                    value={this.state.data.sheet.classInfo}
                    onChange={(event) => {
                      this.setField("classInfo", event.currentTarget.value);
                    }}
                  />
                </React.Fragment>
              )}

              <div className={style.label}>รายละเอียดเนื้อหา</div>
              <div className={style.explainLabelExtra}>
                <div className={style.qcontain}>
                  <div
                    className={style.qimage}
                    id="forbid2"
                    onClick={() =>
                      this.setState({ showexdetail: !this.state.showexdetail })
                    }
                  >
                    กดตรงนี้เพื่อดูตัวอย่าง
                  </div>
                  {this.state.showexdetail && (
                    <img src={exdetail} className={style.qdetailpic} />
                  )}
                </div>
              </div>
              <textarea
                className={style["textArea"]}
                value={this.state.data.sheet.detail}
                onChange={(event) => {
                  this.setField("detail", event.currentTarget.value);
                }}
              />

              <div className={style.label}>ราคาที่ต้องการขาย (บาท)</div>
              <div className={style.explainLabel}>(หน้าละ 3 บาท)</div>
              <input
                type="number"
                className={style["textField"]}
                value={this.state.data.sheet.price}
                onChange={(event) => {
                  this.setField("price", event.currentTarget.value);
                }}
              />

              <div className={style.label}>ภาพหน้าปก</div>
              <div className={style.explainLabel}></div>
              {this.state.updCover ? (
                <div className={style.uploadInfo}>กำลังอัพโหลด</div>
              ) : (
                <React.Fragment>
                  {this.state.data.sheet.store == 32 && (
                    <input
                      type="file"
                      className={style["fileField"]}
                      onChange={(event) => {
                        this.uploadCover(event.currentTarget.files[0]);
                      }}
                    />
                  )}
                  <div className={style.explainLabel}>
                    รูปที่ใช้อยู่{" "}
                    {this.state.data.sheet.store != 32 &&
                      "สร้างอัตโนมัติตอนเซฟ"}{" "}
                    :
                  </div>
                  {this.state.cover ? (
                    <a
                      href={this.state.cover}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt=""
                        src={this.state.cover}
                        className={style["coverImage"]}
                      />
                    </a>
                  ) : (
                    <div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
                  )}
                </React.Fragment>
              )}

              <div className={style.label}>รูปตัวอย่าง</div>
              <div className={style.explainLabel}>
                รูปที่จะให้ผู้ซื้อดูเป็นตัวอย่าง
              </div>
              {this.state.updSample ? (
                <div className={style.uploadInfo}>{this.state.updSample}</div>
              ) : (
                <React.Fragment>
                  <input
                    type="file"
                    className={style["fileField"]}
                    onChange={(event) => {
                      this.uploadSample(event.currentTarget.files);
                    }}
                    multiple
                  />
                  <div className={style.explainLabel}>
                    รูปตัวอย่างที่ใช้อยู่ทั้งหมด :
                  </div>
                  {this.state.data.sheet.examNum > 0 ? (
                    this.renderSamples(this.state.example)
                  ) : (
                    <div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
                  )}
                </React.Fragment>
              )}

              <div className={style.label}>ไฟล์</div>
              <div className={style.explainLabel}>
                ไฟล์ pdf ที่มีเนื้อหาทั้งหมด (ขนาดไฟล์ไม่เกิน 150MB)
              </div>
              {this.state.updPdf ? (
                <div className={style.uploadInfo}>{this.state.updPdf}</div>
              ) : (
                <React.Fragment>
                  <input
                    type="file"
                    className={style["fileField"]}
                    onChange={(event) => {
                      this.uploadPdf(event.currentTarget.files[0]);
                    }}
                  />
                  <div className={style.explainLabel}>ไฟล์ชีทปัจจุบัน :</div>
                  {this.state.pdf ? (
                    <a
                      href={this.state.pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={style.pdfLink}
                    >
                      คลิกเพื่อดูไฟล์
                    </a>
                  ) : (
                    <div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
                  )}
                </React.Fragment>
              )}
              <hr />
              <div className={style.explainLabel}>{this.state.informAuto}</div>
              <div className={style.label}>ข้อความถึงผู้ตรวจสอบ</div>
              <div className={style.explainLabel}>
                หากโดนปฏิเสธมา แต่มีเหตุผลอันควรที่จะโต้แย้ง ให้ใส่ในช่องนี้
              </div>
              <textarea
                className={style["textArea"]}
                value={this.state.data.sheet.message}
                onChange={(event) => {
                  this.setField("message", event.currentTarget.value);
                }}
              />
              <div className={style.buttContainer}>
                {this.state.saving ? (
                  <div className={style.smallLoader} />
                ) : (
                  <React.Fragment>
                    <div
                      onClick={() => this.saveDraft()}
                      className={style.saveButton}
                    >
                      บันทึกการเปลี่ยนแปลง
                    </div>
                    <div
                      onClick={() => this.confirmSend()}
                      className={style.sendButton}
                    >
                      ส่งเข้าระบบเพื่อตรวจสอบ
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  genUniOptions = () => {
    if (this.state.data.store.length === 0) return [{ value: "0", name: "" }];
    return this.state.data.store.map(({ name, short, code }) => ({
      value: code.toString(),
      name: name[0],
    }));
  };

  setUni = async (value) => {
    var dat = this.state.data;
    dat.sheet.store = parseInt(value.value);
    this.setState({ data: dat });
  };

  genCatOptions = () => {
    const ops = [
      { label: "อ่านเข้า ม.4", value: 5 },
      { label: "อ่านเสริมเข้ามหาวิทยาลัย", value: 4 },
      { label: "สอบในห้อง", value: 3 },
      { label: "ไฟนอล", value: 2 },
      { label: "มิดเทอม", value: 1 },
      { label: "อื่น ๆ", value: 0 },
    ];
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };

  genTermOptions = () => {
    const ops = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
    ];
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };

  genTriamTermOptions = () => {
    const ops = [
      { label: "ม.4", value: 1 },
      { label: "ม.5", value: 2 },
      { label: "ม.6", value: 3 },
    ];
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };

  genYearOptions = () => {
    var ops = [];
    var curr = new Date().getFullYear();
    for (var i = 0; i < 10; i++) ops.push(curr - i);
    return ops.map((y) => (
      <option label={y} value={y} key={y}>
        {y}
      </option>
    ));
  };

  setField = async (field, value) => {
    var dat = this.state.data;
    dat.sheet[field] = value;
    this.setState({ data: dat });
  };

  setFieldCode = async (value) => {
    if (this.state.data.sheet.store != 32)
      this.setState({ sCode: value.replace(/\s/g, "").toUpperCase() });
    else this.setState({ sCode: value });
  };

  setFieldName = async (value) => {
    this.setState({ sName: value });
  };

  reloadCover = async (id) => {
    var cover = await Util.checkCover(id);
    await this.setState({ cover: cover });
  };

  loadSamples = () => {
    var urls = [];
    for (var i = 0; i < this.state.data.sheet.examNum; i++)
      urls.push(
        process.env.REACT_APP_SHEET_URL +
          this.state.data.sheet.id +
          "/ex" +
          (i + 1).toString() +
          ".jpg?" +
          Date.now().toString()
      );
    this.setState({ example: urls });
  };

  renderSamples = (urls) => {
    if (!urls) return null;
    return urls.map((url) => (
      <a key={url} href={url} target="_blank" rel="noopener noreferrer">
        <img alt="" src={url} className={style.sampleImage} />
      </a>
    ));
  };

  uploadCover = async (file) => {
    await this.setState({ updCover: true });
    var data = await Util.sendPost("/api/seller/uploadCoverUrl", {
      id: this.state.data.sheet.id,
    });

    var formData = new FormData();
    formData.append("acl", "public-read");
    var keys = Object.keys(data.data.fields);
    for (var i = 0; i < keys.length; i++)
      formData.append(keys[i], data.data.fields[keys[i]]);
    formData.append("file", file);
    await Util.sendFormData(data.data.url, formData);

    await this.setState({ updCover: false });
    this.reloadCover(this.state.data.sheet.id);
  };

  uploadSample = async (files) => {
    await this.setState({
      updSample: ["กำลังขอคิวเพื่ออัพโหลด...", "Requesting upload queue"],
    });
    var data = await Util.sendPost("/api/seller/uploadExamplesUrl", {
      id: this.state.data.sheet.id,
      num: files.length,
    });
    for (var j = 0; j < files.length; j++) {
      await this.setState({
        updSample:
          "กำลังอัพโหลดรูปที่ " +
          (j + 1).toString() +
          " จาก " +
          files.length.toString(),
      });
      var formData = new FormData();
      formData.append("acl", "public-read");
      var keys = Object.keys(data.data[j].fields);
      for (var i = 0; i < keys.length; i++)
        formData.append(keys[i], data.data[j].fields[keys[i]]);
      formData.append("file", files[j]);
      await Util.sendFormData(data.data[j].url, formData);
    }
    var d = this.state.data;
    d.sheet.examNum = files.length;
    await this.setState({ updSample: false, data: d });
    this.loadSamples();
  };

  uploadPdf = async (file) => {
    if (!file.name.endsWith(".pdf")) {
      Util.alertError("โปรดใช้ไฟล์ .pdf");
      return;
    }
    await this.setState({ updPdf: "เริ่มต้นอัพโหลด" });
    var data = await Util.sendPost("/api/seller/uploadPdfUrl", {
      id: this.state.data.sheet.id,
    });
    var formData = new FormData();
    formData.append("acl", "private");
    var keys = Object.keys(data.data.fields);
    for (var i = 0; i < keys.length; i++)
      formData.append(keys[i], data.data.fields[keys[i]]);
    formData.append("Content-Type", "application/pdf");
    formData.append("file", file);
    //xhr
    var xhr = new XMLHttpRequest();
    xhr.open("POST", data.data.url, true);
    xhr.upload.onprogress = async (e) => {
      var percent = Math.ceil((e.loaded / e.total) * 100);
      await this.setState({
        updPdf: "อัพโหลดแล้ว " + percent.toString() + "%",
      });
    };
    xhr.onreadystatechange = async () => {
      // Call a function when the state changes.
      if (xhr.readyState === XMLHttpRequest.DONE) {
        await this.loadPdf();
        await this.setState({ updPdf: false });
      }
    };
    xhr.send(formData);
  };

  loadPdf = async () => {
    var data = await Util.sendPost("/api/seller/getPdf", {
      id: this.state.data.sheet.id,
    });
    await this.setState({ pdf: data.data });
  };

  saveDraft = async () => {
    if (this.state.saving) return;
    await this.setState({ saving: true });
    var clonedObj = Object.assign({}, this.state.data.sheet);
    clonedObj.name = this.state.sCode.trim() + " " + this.state.sName.trim();
    clonedObj.sCode = this.state.sCode.trim();
    clonedObj.sName = this.state.sName.trim();
    var data = await Util.sendPost("/api/seller/saveDraft", clonedObj);
    if (!data.success) Util.alertError("กรุณากรอกราคาเป็นตัวเลข");
    else {
      var today = new Date();
      var time =
        Util.format2(today.getHours()) +
        ":" +
        Util.format2(today.getMinutes()) +
        ":" +
        Util.format2(today.getSeconds());
      this.setState({ informAuto: "เซฟล่าสุด: " + time });
    }
    var id = window.location.search.substring(1);
    try {
      await this.reloadCover(id);
    } catch (err) {}
    await this.setState({ saving: false });
  };

  confirmSend = async () => {
    await this.saveDraft();
    if (
      this.state.data.sheet.store != 999999 &&
      this.state.data.sheet.store != 9997 &&
      this.state.data.sheet.store != 32 &&
      this.state.data.sheet.classInfo.trim().length == 0
    ) {
      Util.alertError("กรุณากรอกข้อมูลคลาส/ตอนเรียน");
      return;
    }
    if (
      this.state.data.sheet.store != 999999 &&
      this.state.data.sheet.store != 9997 &&
      this.state.data.sheet.store != 32 &&
      this.state.data.sheet.detail.trim().length == 0
    ) {
      Util.alertError("กรุณากรอกข้อมูลรายละเอียดเนื้อหา");
      return;
    }

    if (!/^\d.*$/.test(this.state.data.sheet.price)) {
      Util.alertError("ราคาไม่เป็นตัวเลข");
      return;
    }

    const options = {
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              border: "1px solid black",
              width: "50%",
              minWidth: "300px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <img alt="confirm" src={confirm} style={{ width: "100%" }} />
            <br />
            <button
              style={{ width: "50%", cursor: "pointer", height: "50px" }}
              onClick={() => {
                this.onClickNo();
                onClose();
              }}
            >
              ยกเลิก
            </button>
            <button
              style={{ width: "50%", cursor: "pointer", height: "50px" }}
              onClick={() => {
                this.onClickYes();
                onClose();
              }}
            >
              ยืนยัน !
            </button>
          </div>
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    Util.alertConfirm(options);
  };

  nl2br = (text) => {
    return text.split("\n").map((item, key) => {
      return (
        <span className={style.line} key={key}>
          {item}
        </span>
      );
    });
  };

  onClickYes = async () => {
    var data = await Util.sendPost(
      "/api/seller/confirmSend",
      this.state.data.sheet
    );
    if (!data.success) Util.alertError(data.msg[0]);
    else {
      clearInterval(this.state.intervalId);
      var func = async () => {
        await this.setState({ saving: false });
        this.props.history.push("/seller/mysheet");
      };
      Util.alertSuccess(
        "ส่งชีทเข้าสู่การตรวจสอบสำเร็จ สามารถติดตามสถานะได้ที่หน้าขายชีท",
        func
      );
    }
  };

  onClickNo = async () => {
    await this.setState({ saving: false });
  };
}

export default withRouter(withAlert()(EditSheet));
